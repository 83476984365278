import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";


const FaqSettingCustomerAndProductPreferences = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  


  return (
    <>
      <main>
        <section className="page-title">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <div className="heading">
                  <h2>Frequently Asked Questions</h2>
                  <ul className="breadcrumbs">
                    <li>
                      <Link to={`/faq`}>FAQs</Link>
                    </li>
                    <li>Setting customer preferences</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="single-faq pt-5 pb-5">
          <img
            src="assets/images/left-shape.png"
            alt="help-1"
            className="img-fluid-left after-img"
          />
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 text-left mb-4">
                <div className="heading">
                  <h2 className="text-uppercase">Setting customer preferences</h2>
                  <span className="left-divider"></span>
                </div>
              </div>
              <div className="col-lg-8">
                {/* <div className="text-center">
                  <img src="assets/faq/faq-logo.png"  />  
                </div>
                <p style={{"marginTop":"0in","marginRight":"0in","marginBottom":"8pt","marginLeft":"0in","fontSize":"11pt","fontFamily":"'Calibri', sansSerif","textAlign":"center"}}>&nbsp;</p> */}
                {/* <p style={{"marginTop":"0in","marginRight":"0in","marginBottom":"8pt","marginLeft":"0in","fontSize":"11pt","fontFamily":"'Calibri', sansSerif","textAlign":"center"}}>
                    <strong><span style={{"fontSize":"27px","lineHeight":"107%"}}>Setting customer and product preferences</span></strong>
                </p> */}
                
                <p>
                    Welcome to MortgageBox.co.uk, where we give customers more options, better deals, and a higher acceptance rate by putting them in contact with 5 FCA registered mortgage advisers.&nbsp;
                </p>
                <p>
                    In the next few pages, we walk you through the steps of setting customer and product preferences, along with an initial message. &nbsp;
                </p>
                
                <strong style={{"fontSize":"18px","lineHeight":"107%"}}><span>3. Setting customer preferences</span></strong>
                
                
                <ul className="decimal_type" style={{"listStyleType":"undefined","marginTop":"0.1in","paddingLeft":"0"}}>
                    <li>
                      <strong><span style={{"fontSize":"14px"}}>3.1 Navigate to the My Account page</span></strong>
                    </li>
                </ul>
                <p style={{"marginTop":"0in","marginRight":"0in","marginBottom":"8pt","marginLeft":"0in","fontSize":"11pt","fontFamily":"'Calibri', sansSerif"}}>
                    <span style={{"fontSize":"16px","lineHeight":"107%"}}>From ponfei.com either click&nbsp;</span>
                    <a href="https://ponfei.com/adviser/my-account"><span style={{"fontSize":"16px","lineHeight":"107%"}}>My Account</span></a><span style={{"fontSize":"16px","lineHeight":"107%"}}>&nbsp;or directly enter&nbsp;</span>
                    <a href="https://ponfei.com/adviser/my-account">https://ponfei.com/adviser/my-account</a><span>. Then scroll down to Preferences</span>
                </p>
                <p>
                  <span style={{"float":"left","width":"100%","textAlign":"center"}}>
                    <img src="assets/faq/3.1.png" style={{"border":"2px solid #ddd"}} />
                  </span>
                </p>
                
                <ul className="decimal_type" style={{"listStyleType":"undefined","marginTop":"0.25in","paddingLeft":"0","float":"left","width":"100%"}}>
                    <li>
                      <strong><span style={{"fontSize":"14px"}}>3.2 Let&rsquo;s set up Product Preferences</span></strong>
                    </li>
                </ul>
                <p>
                    <span>This section allows you to define the mortgage products you are interested in along with the minimum and maximum mortgage size.</span>
                </p>
                <p>
                    <em><span>When you are happy click &lsquo;save&rsquo;</span></em>
                </p>
                <p>
                  <span style={{"float":"left","width":"100%","textAlign":"center"}}>
                    <img src="assets/faq/3.2.png" style={{"border":"2px solid #ddd"}} />
                  </span>
                </p>
                <ul className="decimal_type" style={{"listStyleType":"undefined","marginTop":"0.25in","paddingLeft":"0","float":"left","width":"100%"}}>
                    <li>
                        <strong><span>3.3 Let&rsquo;s set up Customer Preferences</span></strong>
                    </li>
                </ul>
                <p>
                    <span>This section allows you to filter the types of customers you are interested in.&nbsp;</span>
                </p>
                <p>
                    <span>
                        As an example, if you do not wish to see &lsquo;Adverse Credit&rsquo; customers then simply toggle that option off. Alternatively, if the max &lsquo;Loan To Value&rsquo; you wish to consider is 90% then that can also be set.
                    </span>
                </p>
                <p>
                    <em><span>When you are happy click <b>&lsquo;save&rsquo;</b></span></em>
                </p>
                <p>
                  <span style={{"float":"left","width":"100%","textAlign":"center"}}>
                    <img src="assets/faq/3.3.png" style={{"border":"2px solid #ddd"}} />
                  </span>
                </p>

                <ul className="decimal_type" style={{"listStyleType":"undefined","marginTop":"0.25in","paddingLeft":"0","float":"left","width":"100%"}}>
                    <li>
                        <strong><span>3.4 Customer location</span></strong>
                    </li>
                </ul>
                <p>
                    <span>
                        Tell us the area you cover so we can show you leads for your location. If you cover all of the UK then select &apos;National&apos;. This was initially set on sign-up, here is where you can change it.
                    </span>
                </p>
                
                <p>
                  <span style={{"float":"left","width":"100%","textAlign":"center"}}>
                    <img src="assets/faq/3.4.png" style={{"border":"2px solid #ddd"}} />
                  </span>
                </p>
                
                <ul className="decimal_type" style={{"listStyleType":"undefined","marginTop":"0.25in","paddingLeft":"0","float":"left","width":"100%"}}>
                    <li>
                        <strong><span>3.5 First message</span></strong>
                    </li>
                </ul>
                <p>
                    <span>
                        Once you purchase a lead you can message the customer. &lsquo;First Message&rsquo; is a time-saving template where you can store a welcome message for new leads. This message is optional and can be customised before sending.
                    </span>
                </p>
                <p>
                    <span>We provide an example as a starting point.</span>
                </p>
                <p>
                  <span style={{"float":"left","width":"100%","textAlign":"center"}}>
                    <img src="assets/faq/3.5.png" style={{"border":"2px solid #ddd"}} />
                  </span>
                </p>
                <ul className="decimal_type" style={{"listStyleType":"undefined","marginTop":"0.25in","paddingLeft":"0","float":"left","width":"100%"}}>
                    <li>
                      <strong><span>3.6 Lead value calculation</span></strong>
                  </li>
                </ul>
                <p>
                    <span>
                        The last option under preferences, Defaults, is the option that allows you to create your own lead value estimate based on product and fee. This allows you to easily see a leads potential net worth.
                    </span>
                </p>
                <p>
                  <span style={{"float":"left","width":"100%","textAlign":"center"}}>
                    <img src="assets/faq/3.6.png" style={{"border":"2px solid #ddd"}} />
                  </span>
                </p>
              </div>
            </div>
          </div>
          <img
            src="assets/images/right-shape.png"
            alt="help-1"
            className="img-fluid before-img"
          />
        </section>
      </main>
    </>
  );
};

export default FaqSettingCustomerAndProductPreferences;
