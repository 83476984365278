import React, { useEffect, useRef, useState } from "react";
import { Link,useHistory } from "react-router-dom";
import { FaqData } from "../components/common/service";
import useLoggedInUserData from "../utility/useLoggedInUserData";
const currentUser = JSON.parse(localStorage.getItem('loggedIn_user') || '{}');

const Faq = () => {
  const [faqPopular, setFaq] = useState<any>([]);
  const [faqCategory, setFaqCategory] = useState<any>([]);
  const [isAdviser, setIsAdviser] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const imageUrl = `${process.env.REACT_APP_IMAGE_URL}faq_category/original/`;
  const user = useLoggedInUserData();
  const history = useHistory();

  useEffect(() => {
    ContentPagesCall();
    localStorage.removeItem('faqList');
  }, []);

  const ContentPagesCall = async () => {
    setIsLoading(true);
    const post:any = { 'audience' : currentUser!=''?currentUser.user_role:0};
    let userType:any = currentUser!=''?currentUser.user_role:0;
    if(userType==1){
      setIsAdviser(true);
    }
    else{
      setIsAdviser(false);
    }
    const res = await FaqData(post);
    if (res?.success || res.status) {
      setFaq(res.data.list);
      setFaqCategory(res.data.category);
    }
    setIsLoading(false);
  };
  function setFaqLocalStorage(faq: any) {
    localStorage.setItem('faqList',JSON.stringify(faq));
    history.push("/faq-inner");
  }
  return (
    <>
      <section className="page-title">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="heading">
                <h2>Frequently Asked Questions</h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="position-relative pt-5 pb-5">
        <img
          src="assets/images/left-shape.png"
          alt="help-1"
          className="img-fluid-left after-img"
        />
        <div className="container">
          <div className="row mb-5">
            <div className="col-lg-12 text-left mb-4">
              <div className="heading">
                <h2 className="text-uppercase">popular</h2>
                <span className="left-divider"></span>
              </div>
            </div>
            {faqPopular.length?
            ( faqPopular.map(
                (row: any, index: any) =>
            <div className="col-lg-6">
              <div id="accordion">
                <div className="card">
                <div className="card-header">
                  <a
                    className="card-link"
                    data-toggle="collapse"
                    href={"#faq_" + row.id}
                  >
                    {row.question}
                  </a>
                </div>
                <div id={"faq_" + row.id} className="collapse" data-parent="#accordion">
                  <div className="card-body" dangerouslySetInnerHTML={{__html: row.answer}}>
                  </div>
                </div>
              </div>
              
              </div>
            </div>
            )):
            <div className="col-lg-6">
                <p>Popular faq not found</p>
              </div>
}
          </div>
          <div className="row">
            <div className="col-lg-12 text-left mb-4">
              <div className="heading">
                <h2 className="text-uppercase">topics</h2>
                <span className="left-divider"></span>
              </div>
            </div>
            {faqCategory.length?
            (faqCategory.map(
                (category: any, index: any) =>
                <div className="col-lg-3 col-md-4 col-6">
                  <div className="topic-box">
                    <a onClick={() => setFaqLocalStorage(category)}>
                      {/* to={`/faq-inner`} */}
                      <div className="icon">
                        {/* <i className="fal fa-user"></i> */}
                        <img src={imageUrl+""+category.image} alt="help-1" style={{width: 80}}/>
                      </div>
                      <div className="topic-content">
                        <h5>{category.name}</h5>
                        <p>{category.sub_title}</p>
                      </div>
                    </a>
                  </div>
                </div>
            )):
            <div className="col-lg-3 col-md-4 col-6">
             
            </div>
            }
            {isAdviser ? 
              <div className="col-lg-3 col-md-4 col-6">
                <div className="topic-box">
                  <Link to={`/faq/adviser-registration-step-by-step-guide`}>
                    <div className="icon">
                      <img src="https://ponfei.com/backend/public/upload/faq_category/original/1690112005197199822.png" alt="help-1" style={{width: 80}}/>
                    </div>
                    <div className="topic-content">
                      <h5>Registration</h5>
                      <p>Step-by-step guide</p>
                    </div>
                  </Link>
                </div>
              </div>
             : null
            }
            {isAdviser ? 
              <div className="col-lg-3 col-md-4 col-6">
                <div className="topic-box">
                <Link to={`/faq/creating-a-professional-looking-profile`}>
                    <div className="icon">
                      <img src="https://ponfei.com/backend/public/upload/faq_category/original/1690112005197199822.png" alt="help-1" style={{width: 80}}/>
                    </div>
                    <div className="topic-content">
                      <h5>Your profile</h5>
                      <p>Step-by-step guide</p>
                    </div>
                  </Link>
                </div>
              </div>
             : null
            }
            {isAdviser ? 
              <div className="col-lg-3 col-md-4 col-6">
                <div className="topic-box">
                  <Link to={`/faq/setting-customer-and-product-preferences`}>
                    <div className="icon">
                      <img src="https://ponfei.com/backend/public/upload/faq_category/original/1690112005197199822.png" alt="help-1" style={{width: 80}}/>
                    </div>
                    <div className="topic-content">
                      <h5>Preferences</h5>
                      <p>Product & Customer</p>
                    </div>
                  </Link>
                </div>
              </div>
             : null
            }
            {isAdviser ? 
              <div className="col-lg-3 col-md-4 col-6">
                <div className="topic-box">
                  <Link to={`/faq/viewing-and-accepting-a-lead`}>
                    <div className="icon">
                      <img src="https://ponfei.com/backend/public/upload/faq_category/original/1690112005197199822.png" alt="help-1" style={{width: 80}}/>
                    </div>
                    <div className="topic-content">
                      <h5>Prospects & Leads</h5>
                      <p>Step-by-step guide</p>
                    </div>
                  </Link>
                </div>
              </div>
             : null
            }           
          </div>
        </div>
        <img
          src="assets/images/right-shape.png"
          alt="help-1"
          className="img-fluid before-img"
        />
      </section>
    </>
  );
};

export default Faq;
