import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import StepOne from "../components/signup/StepOne";
import StepTwo from "../components/signup/StepTwo";
import StepThree from "../components/signup/StepThree";
import { addAdviceArea, registerUser, sendMailMatchedLeads } from "../components/common/service";
import { Link } from "react-router-dom";
import { AuthContext } from "../shared/context/auth-context";
import Header from "../components/Header";

// import { validateEmail } from "../../utility/commonUtils";
import { validateEmail } from "../utility/commonUtils";

import $ from "jquery";
import "bootstrap";
import { DEFAULT_CURRENCY, JWT_TOKEN, LOGGEDIN_USER, ADVISER_LEADS_MATCHES } from "../Constants";
import useLoggedInUserData from "../utility/useLoggedInUserData";

const initialState = {
  user_id: 0,
  service_type: "",
  service_type_id: 1,
  request_time: "as soon as possible",
  property_currency: DEFAULT_CURRENCY,
  property: "",
  size_want_currency: DEFAULT_CURRENCY,
  size_want: "",
  combined_income_currency: DEFAULT_CURRENCY,
  combined_income: "",
  description: "",
  self_employed: 0,
  non_uk_citizen: 0,
  adverse_credit: 0,
  contact_preference_face_to_face: 0,
  contact_preference_online: 0,
  contact_preference_telephone: 0,
  contact_preference_evening_weekend: 0,
  advisor_preference_local: 0,
  advisor_preference_gender: 0,
  advisor_preference_language: "English",
  hear_about_us: "",
  fees_preference: 1,
  name: "",
  email: "",
  post_code: "",
  password: "",
  invited_by: "",
};

const SignUp = () => {
  const auth = useContext(AuthContext);
  const { isLoggedIn } = useContext(AuthContext);
  const history = useHistory();
  const queryParams = new URLSearchParams(window.location.search);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const invited_by = queryParams.get("invited_by");
    const invitedToEmail = queryParams.get("invitedToEmail");
    const invitedForCompany = queryParams.get("invitedForCompany");

  const { addToast } = useToasts();
  const [formState, setFormState] = useState<object | any>({
    ...initialState,
    invited_by: invited_by || "",
    invitedToEmail: invitedToEmail,
    invitedForCompany: invitedForCompany,
  });

  const handleFormData = (key: string, value: string) => {
    setFormState((prevState: object | any) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  };
  const user = useLoggedInUserData();
  const [steps, setSteps] = useState<number>(1);
  const [innerStep, setInnerStep] = useState<number>(1); //for StepTwo component only
  const handleNextStep = (newStep: number) => {
    if (newStep === 2) {
      if (formState.service_type_id === "") {
        addToast("Please select option.", { appearance: "error" });
        return;
      }
    }
    setSteps(() => newStep);
  };

  const handleSignupSubmit = async () => {
    const res = await registerUser(formState);
    if (res.success || res.status) {
      setFormState(initialState);
      localStorage.setItem(JWT_TOKEN, res.token);
      localStorage.setItem(LOGGEDIN_USER, JSON.stringify(res.data));
      sendMail(res.area_id);
      $("#advice_area_finish_modal").modal("show");
      auth.login(res.data);
      // handlePageState({ responseMessage: "Login success", isError: false });
      // $("#login_modal").modal("hide");
      // ($("#login_modal") as any).modal("hide");
      // $("body").removeClass("modal-open");
      // $(".modal-backdrop").remove();
      // if (res.data.user_role) {
      //   history.push(ADVISER_LEADS_MATCHES, { from: "register" });
      // } else {
      //   history.push("/dashboard", { from: "/" });
      // }
    } else {
      addToast(res.message || res?.error?.email[0], { appearance: "error" });
    }
  };

  const handleSubmit = async () => {
    const res = await addAdviceArea(formState);
    if (res.success || res.status) {
      setFormState(initialState);
      sendMail(res.area_id);
      $("#advice_area_finish_modal").modal("show");
    } else {
      addToast(res.message, { appearance: "error" });
    }
  };
  const sendMail = async (area_id:any) => {
    const res = await sendMailMatchedLeads(area_id);
  };

  const closePopup = async () => {
    $("#advice_area_finish_modal").modal("hide");
    ($("#advice_area_finish_modal") as any).modal("hide");
    $("body").removeClass("modal-open");
    $(".modal-backdrop").remove();
    if (user.user_role) {
      history.push(ADVISER_LEADS_MATCHES, { from: "register" });
    } else {
      history.push("/dashboard", { from: "/" });
    }
  }

  // const getTeamMember = async (invited_to:string) => {
  //   setIsLoading(true);
  //   const post:any = { 'id' : invited_to};
  //   const res = await getTeamMember(post);
  //   if (res?.success || res?.status) {
  //     setUserNotes(res.data);
  //   }
  //   setIsLoading(false);
  // };

  return (
    <>
      {isLoggedIn ? (
        <Header />
      ) : (
        <header className="site-header inner-page-header advice-area-header">
          <div className="container">
            <div className="row">
              <div className="col-12 d-flex align-items-center justify-content-center position-relative">
                {steps > 1 && (
                  <Link to={`/`} className="back-btn">
                    <i className="fa fa-angle-left" aria-hidden="true"></i>
                    Home
                  </Link>
                )}
                <nav className="text-center">
                  <Link to={`/`}>
                    <img
                      src="image1.png"
                      alt="site-logo"
                      className="img-fluid site-logo"
                    />
                  </Link>
                </nav>
              </div>
            </div>
          </div>
        </header>
      )}
      <main className="dashboard advice-area-content">
        <section className="page-title"></section>

        {steps === 1 && (
          <StepOne
            formState={formState}
            nextStep={handleNextStep}
            setFormData={handleFormData}
          />
        )}
        {steps === 2 && (
          <StepTwo
            formState={formState}
            innerStep={innerStep}
            nextStep={handleNextStep}
            setFormData={handleFormData}
          />
        )}
        {steps === 3 && (
          <StepThree
            preLastStep={() => {
              setSteps(() => 2);
              setInnerStep(() => 3); //for StepTwo component final step
            }}
            nextStep={handleNextStep}
            formState={formState}
            finalStep={handleSubmit}
            signupStep={handleSignupSubmit}
            setFormData={handleFormData}
          />
        )}
      </main>

      {/* Thank you Modal */}
      <div
        className="modal fade popup-modal"
        id="advice_area_finish_modal"
        role="dialog"
        aria-labelledby="advice_area_finishTitle"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body text-center">
              <h3>All done. What happens next?</h3>
              <p>
                  We are now matching you with up to five FCA regulated mortgage advisers based on your unique mortgage need, who will contact you to arrange a free, no-obligation chat.
              </p>
              <p>
                  Typically, this will be within 24 hours if not a lot sooner.
              </p>
              <p>
                  Chat, message, speak to the mortgage experts. Compare advice, deals, incentives and rating and select the one right for you.
              </p>
              <button
                type="button"
                className="btn-bg-blue text-uppercase"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() =>
                  closePopup()
                }
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
