import React from "react";
import { Link } from "react-router-dom";
import Login from "./common/Login";
import { useLocation } from 'react-router-dom';

const AdviserFooter = () => {
  const location = useLocation();
  const currentPath = location.pathname; // Get the current URL path
  //const pathname = window.location.pathname; 
  console.log(currentPath);
  $('body:not(.newtoggle)').click(function(e){
    $('#navbarNav').removeClass('show');
  });
  
  return (
    <>
    {currentPath!='/need'?
      <footer>
        <div className="site-footer mt-2-">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="footer-1">
                  <img
                    src="image1.png"
                    alt="site-logo"
                    className="img-fluid w-75"
                  />
                  <p style={{ textAlign: "left" }}>MortgageBox.co.uk is not regulated by the FCA as we do not provide any financial services or advice. We are a technology company that provides a directory/introduction service for financial professionals.</p>
                  <ul className="social-icon pl-0 ml-0 d-block">
                    <li>
                      <a href="#facebook">
                        <i className="fa fa-facebook" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#instagram">
                        <i className="fa fa-instagram" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#twitter">
                        <i className="fa fa-twitter" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#youtube">
                        <i
                          className="fa fa-youtube-play"
                          aria-hidden="true"
                        ></i>
                      </a>
                    </li>
                    <li>
                      <a href="#linkedin">
                        <i className="fa fa-linkedin" aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 footer-2">
                <div className="footer-middle">
                  <h6 className="text-uppercase">quick links</h6>
                  <div className="row">
                    <div className="col-5">
                      <ul className="pl-0 ml-0 d-block">
                        <li>
                          <Link to={`/adviser/about`}>About Us</Link>
                        </li>
                        <li>
                          <Link to={`/adviser/services`}>Services</Link>
                        </li>
                        <li>
                          <Link to={`/contact-us`}>Contact Us</Link>
                        </li>
                        <li>
                          <Link to={`/faq`}>Faq</Link>
                        </li>
                        <li>
                          <Link to={`/sitemap`}>Sitemap</Link>
                        </li>
                      </ul>
                    </div>
                    <div className="col-6">
                      <ul className="pl-0 ml-0 d-block">
                        <li>
                          <Link to={`/adviser/privacy-policy`}>Privacy Policy</Link>
                        </li>
                        <li>
                            <Link to={`/adviser/terms-conditions`}>Terms & Conditions</Link>
                        </li>
                        <li>
                          <Link to={`/adviser/press`}>Press</Link>
                        </li>
                        <li>
                          <Link to={`/postcode-search`}>Browse</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 footer-last">
                <div className="footer-3">
                  <h6 className="text-uppercase">
                    ARE YOU A MORTGAGE ADVISER?
                  </h6>
                  <p>
                    Join a buzzing ecosystem used by people actively looking for
                    a mortgage adviser
                  </p>
                  <Link
                    to={`/adviser`}
                    className="pl-0 nav-link mb-nav-link page-scroll active"
                  >
                    <img alt="img" src="/assets/images/newpagesimages/footer.png" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <p>
                  Copyright &copy; {new Date().getFullYear()} by ponfei.com All Right Reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      :null}
      <div
        className="modal fade login_popup"
        id="login_modal"
        role="dialog"
        aria-labelledby="login_modalTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content border-0">
			<div className="modal-body text-center pt-0 pb-0">
              <div className="modal-header">
				<button type="button" className="close" data-dismiss="modal">&times;</button>
			</div>
			  <div className="row">
                <div className="col-md-6 left-content">
                  <div className="content">
                    <h5 className="mb-3 text-white">
                      Welcome to ponfei.com
                    </h5>
                    <img
                      src="assets/images/logo.png"
                      alt="site_logo"
                      className="img-fluid mt-4 mb-4"
                    />
                    <div className="para-boder">
                      <p>
                        The smart and cost effective way for finding new
                        clients. We help you grow!
                      </p>
                      <p>
                        Login to your account to see the latest leads, track and
                        manage clients, view fees generated and much much more
                        ...
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 right-content">
                  <Login />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* TODO: Forgot password */}
    </>
  );
};

export default AdviserFooter;
