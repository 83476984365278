import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import {
  ADVISER_DASHBOARD,
  ADVISER_LEADS_ACCEPTED,
  ADVISER_LEADS_MATCHES,
  ADVISER_MY_ACCOUNT,
  LOGGEDIN_USER,
} from "../Constants";
import { getAdviserUserProfile } from "../components/common/service";
import { AuthContext } from "../shared/context/auth-context";

interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => {
  const { pathname } = useLocation();
  let { user, isLoggedIn } = useContext(AuthContext);
  const loggedInUserInfo = localStorage.getItem(LOGGEDIN_USER);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isNavOpen, setIsNavOpen] = useState<boolean>(false);
  const navbarRef = useRef<HTMLDivElement>(null);

  // Close navbar when clicking outside of it
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        navbarRef.current &&
        !navbarRef.current.contains(event.target as Node) &&
        !(event.target as Element).closest('.navbar-toggler')
      ) {
        setIsNavOpen(false);
      }
    };
  
    document.addEventListener("mousedown", handleOutsideClick);
  
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const toggleNav = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsNavOpen(prev => !prev);
  };

  const closeNav = () => {
    setIsNavOpen(false);
  };

  

  user = user?.id
  ? user
  : loggedInUserInfo === null
  ? {}
  : JSON.parse(loggedInUserInfo);
  const userMenus = (
    <>
      <li className="nav-item mb-nav-item">
        <NavLink
          to={`/dashboard`}
          className="nav-link mb-nav-link page-scroll"
          activeClassName="active"
          onClick={closeNav}
        >
          Dashboard
        </NavLink>
      </li>
      <li className="nav-item mb-nav-item">
        <NavLink
          to={`/account`}
          className="nav-link mb-nav-link page-scroll"
          activeClassName="active"
          onClick={closeNav}
        >
          My Account
        </NavLink>
      </li>
      <li className="nav-item mb-nav-item">
        <NavLink
          to={`/postcode-search`}
          className="nav-link mb-nav-link page-scroll"
          activeClassName="active"
          onClick={closeNav}
        >
          Browse
        </NavLink>
      </li>
      <li className="nav-item mb-nav-item">
        <Link to={`/logout`} className="nav-link mb-nav-link page-scroll" onClick={closeNav}>
          Logout
        </Link>
      </li>
      {user.status!=0?
      <li className="nav-item mb-nav-item">
        <NavLink
          to={`/advice-area`}
          className="nav-link mb-nav-link advisor-btn page-scroll login-btn text-uppercase"
          activeClassName="active"
        >
          Post a mortgage need
        </NavLink>
      </li>
      :""}
    </>
  );

  const advisorMenus = (
    <>
      <li className="nav-item mb-nav-item">
        <NavLink
          to={ADVISER_DASHBOARD}
          className="nav-link mb-nav-link page-scroll"
          activeClassName="active"
          onClick={closeNav}
        >
          Dashboard
        </NavLink>
      </li>
      <li className="nav-item mb-nav-item">
        <NavLink
          to={ADVISER_LEADS_MATCHES}
          className="nav-link mb-nav-link page-scroll"
          activeClassName="active"
          onClick={closeNav}
        >
          Leads
        </NavLink>
      </li>
      <li className="nav-item mb-nav-item">
        <NavLink
          to={ADVISER_LEADS_ACCEPTED}
          className="nav-link mb-nav-link page-scroll"
          activeClassName="active"
          onClick={closeNav}
        >
          Purchased
        </NavLink>
      </li>
      <li className="nav-item mb-nav-item">
        <NavLink
          to={ADVISER_MY_ACCOUNT}
          className="nav-link mb-nav-link page-scroll"
          activeClassName="active"
          onClick={closeNav}
        >
          My Account
        </NavLink>
      </li>
      <li className="nav-item mb-nav-item">
        <Link to={`/logout`} className="nav-link mb-nav-link page-scroll" onClick={closeNav}>
          Logout
        </Link>
      </li>
    </>
  );

  let publicMenus = (
    <>
      <li className="nav-item mb-nav-item">
        <NavLink to={`/chat-gpt`} className="nav-link mb-nav-link page-scroll color-white" onClick={closeNav}>
          AI Chatbot
        </NavLink>
      </li>
      <li className="nav-item mb-nav-item">
        <NavLink to={`/adviser`} className="nav-link mb-nav-link page-scroll color-white" onClick={closeNav}>
          Are you an adviser?
        </NavLink>
      </li>
      <li className="nav-item mb-nav-item">
        <NavLink to={`/need`} className="nav-link mb-nav-link page-scroll login-btn text-uppercase" onClick={closeNav}>
          Find your Adviser
        </NavLink>
      </li>
      <li className="nav-item mb-nav-item">
        <a
          href="javascript:void(0)"
          data-toggle="modal"
          data-target="#login_modal"
          className="nav-link mb-nav-link page-scroll login-btn text-uppercase"
          onClick={closeNav}
        >
          Login
        </a>
      </li>
    </>
  );

  if (pathname === "/advisers") {
    publicMenus = (
      <>
        <li className="nav-item mb-nav-item">
          <a
            href="javascript:void(0)"
            data-toggle="modal"
            data-target="#login_modal"
            className="nav-link mb-nav-link page-scroll"
            onClick={closeNav}
          >
            Login
          </a>
        </li>
        {/* <li className="nav-item mb-nav-item">
          <NavLink
            to={"/pricing"}
            className="nav-link mb-nav-link page-scroll"
            activeClassName="active"
            onClick={closeNav}
          >
            Pricing
          </NavLink>
        </li> */}
        {/* <li className="nav-item mb-nav-item">
          <NavLink
            to={`/need`}
            className="nav-link mb-nav-link advisor-btn page-scroll login-btn text-uppercase`}
            onClick={closeNav}
          >
            Do you have a mortgage need?
          </NavLink>
        </li> */}
      </>
    );
  }

  return (
    <header className="site-header inner-page-header">
      <div className="container">
        <nav className="navbar navbar-expand-xl navbar-light mb-navbar-light w-100 p-0">
          <NavLink to={`/`} className="navbar-brand mb-navbar-brand">
            <img src="image1.png" alt="site-logo" className="img-fluid site-logo w-75" />
          </NavLink>

          <button
  className="navbar-toggler mb-navbar-toggler newtoggle navtogglenew"
  onClick={toggleNav}
  type="button"
  aria-expanded={isNavOpen ? "true" : "false"}
  aria-label="Toggle navigation"
>
  <span className="navbar-toggler-icon mb-navbar-toggler-icon"></span>
</button>

          <div className={`collapse navbar-collapse mb-navbar-collapse ${isNavOpen ? "show" : ""}`} ref={navbarRef}>
            <ul className="navbar-nav ml-auto mt-2 mt-lg-0 mb-navbar-nav">
              {isLoggedIn ? (user.user_role ? advisorMenus : userMenus) : publicMenus}
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
